<template>
  <v-app>
    <v-main style="overflow-x: hidden">
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'Admin'
}
</script>
